import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as MeActions from './me.actions';
import {TAccountModel} from "@revolve-app/app/core/metadata/types/account.models";
import Session from "@revolve/meta-data/metadata/interfaces/Session";
import {AccountMinimalResponse} from "@revolve/meta-data/roots/account-microservice/metadata.errors";
import IpSession from "@revolve/meta-data/metadata/interfaces/IpSession";

export const ME_FEATURE_NAME = 'me';
export interface MeState {
  isLoggedIn: boolean,
  checkEmail: string,
  session:  Session | null,
  ipSession: IpSession | null,
  account:AccountMinimalResponse | null
  lastTryTime: number
  stateChangeEnable: boolean,
  initialInfo: any
}

export const meInInitialState: MeState = {
  isLoggedIn: false,
  session: null,
  ipSession: null,
  checkEmail: '',
  account: null,
  lastTryTime: 0,
  stateChangeEnable: true,
  initialInfo: {
    notSeenRooms: []
  }
};

export const meReducer = createReducer(
  meInInitialState,
  on(MeActions.updateUserSession, (state, payload) => ({ ...state, session: payload.session, isLoggedIn:!!payload.session})),
  on(MeActions.getMeSuccessRequest, (state, {payload}) => {
    return {...state, account: payload.account, ipSession: payload.ipSession,  isLoggedIn:!!payload.account }}),
  on(MeActions.onSocketInitialInfo, (state, {payload}) => ({ ...state, initialInfo: {...state.initialInfo, ...payload}})),
  on(MeActions.changeGlobalMessagesCount, (state, payload) => {
    if (state.initialInfo.notSeenRooms) {
      let updatedRooms = [...state.initialInfo.notSeenRooms]; // Create a shallow copy of the array

      let index = updatedRooms.findIndex((x: any) => x.roomId === payload.roomId);
      if (index === -1 && payload.count > 0) {
        // If room is not found and count is positive, add a new entry
        updatedRooms.push({
          roomId: payload.roomId,
          notSeenMessagesCount: payload.count
        });
      } else if (index !== -1) {
        // If room is found, update the count
        updatedRooms[index] = {
          ...updatedRooms[index], // Copy the existing room object
          notSeenMessagesCount: updatedRooms[index].notSeenMessagesCount + payload.count
        };
      }

      return {
        ...state,
        initialInfo: {
          ...state.initialInfo,
          notSeenRooms: updatedRooms // Update the notSeenRooms array in the state
        }
      };
    }

    return state; // Return the state unchanged if notSeenRooms is not defined
  })

);

export function me(state: MeState , action: Action) {
  return meReducer(state, action);
}

export const selectMeState = createFeatureSelector<MeState>(ME_FEATURE_NAME);
export const selectIsLoggedIn = createSelector(
  selectMeState,
  (state: MeState) => state.isLoggedIn
);
export const selectMeAccount = createSelector(
  selectMeState,
  (state: MeState) => state.account
);

export const selectInitialInfo = createSelector(
  selectMeState,
  (state: MeState) => state.initialInfo
);

